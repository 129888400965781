<template>
  <div class="table-number bg-nColorYellow" v-if="businessName">
    <div class="mainContent gap-4 justify-items-center text-center">
      <div class="flex flex-col justify-center items-center pt-8">
        <img :src="logo" class="w-1/3" />
        <div
          class="text-black text-5xl text-center mt-2"
          v-if="businessName && businessLocation"
        >
          <span class="font-ZuumeExtraBold business-name" ref="businessNameRef">
            {{ businessName }}
          </span>
          <div class="text-black text-base font-ZuumeSemiBold">
            {{ businessLocation }}
          </div>
        </div>
      </div>

      <div class="grid justify-center gap-4 mt-4">
        <input
          type="text"
          name="email"
          ref="email"
          id="email"
          v-model="email"
          placeholder="Email ID"
          class="textInput mt-2"
          autofocus
          required
        />
        <input
          class="textInput"
          type="text"
          :maxlength="5"
          @input="handleInput"
          v-model="tableNumber"
          placeholder="Enter your table number here"
        />
      </div>
      <div v-show="errorMsg" class="text-red-500 mx-8 mt-4">
        <p class="text-sm">{{ errorMsg }}</p>
      </div>
    </div>
    <div class="font-InterMedium text-sm text-center mx-8 mt-4">
      Please ask any of the staff members if you can't find the number on the
      table.
    </div>
    <div class="w-full absolute bottom-14">
      <div class="flex justify-center">
        <button
          @click.prevent="addTableNumber()"
          class="shadow-border-orange-right bg-black rounded-3xl w-5/6 text-white text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Submit
        </button>
      </div>
    </div>
    <!--Absolute bottom takes REM value-->
    <Copyright :absoluteBottom="'1'" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Copyright from "@/components/elements/Copyright.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "ClaimFreebie",
  components: { Copyright },
  data() {
    return {
      businessName: this.$store.getters.businessName,
      businessLocation: this.$store.getters.businessLocation,
      logo: this.$store.getters.businessLogo,
      errorMsg: null,
      email: "",
      tableNumber: "",
    };
  },
  mounted() {
    this.resizeText();
  },
  methods: {
    handleInput(event) {
      // Remove any non-alphanumeric characters from the input
      this.tableNumber = event.target.value
        .replace(/[^a-zA-Z0-9]/g, "")
        .slice(0, 5);
    },
    addTableNumber() {
      if (this.email && this.tableNumber) {
        ApiService.post(apiResource.claimFreebie.addTableNumber, {
          business_slug: this.$route.params.business,
          schedule_id: this.$route.params.schedule_id,
          email: this.email,
          table_number: this.tableNumber,
        })
          .then(() => {
            this.$router.replace({
              name: "ClaimFreebie",
              params: {
                business: this.$route.params.business,
                schedule_id: this.$route.params.schedule_id,
              },
            });
          })
          .catch(() => {
            this.goBackToLobby();
          });
      } else {
        this.isSubmitLoading = false;
        toast.error("Please enter a valid email id and table number", {
          timeout: 2000,
        });
      }
    },
    goBackToLobby() {
      this.$router.push({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    resizeText() {
      const businessNameElement = this.$refs.businessNameRef;
      const containerWidth = businessNameElement.clientWidth;
      const originalText = this.businessName;
      let fontSize = 30; // Default font size
      const maxFontSize = 50; // Maximum font size

      // Create a temporary element to measure the width of the text
      const tempElement = document.createElement("span");
      tempElement.style.visibility = "hidden";
      tempElement.style.fontSize = fontSize + "px";
      tempElement.textContent = originalText;
      document.body.appendChild(tempElement);

      // Reduce font size until it fits within the container width
      while (tempElement.clientWidth > containerWidth && fontSize > 0) {
        fontSize--;
        tempElement.style.fontSize = fontSize + "px";
      }

      // Remove the temporary element
      document.body.removeChild(tempElement);

      // Set the final font size for the business name element
      businessNameElement.style.fontSize =
        Math.min(fontSize, maxFontSize) + "px";
    },
  },
};
</script>

<style scoped lang="postcss">
.table-number {
  height: 100dvh;
}
.textInput {
  @apply border border-black rounded-lg block appearance-none focus:bg-nColorOrange focus:outline-none bg-transparent w-80 py-1 px-2 placeholder-black font-ZuumeExtraBold text-center text-3xl tracking-wider;
}
.textInput::placeholder {
  @apply font-ZuumeSemiBold text-nColorOrange text-3xl tracking-normal;
}
</style>